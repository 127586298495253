// extracted by mini-css-extract-plugin
export var softBlueGradient = "OurClients-module--soft-blue-gradient--3EsUA";
export var clients = "OurClients-module--clients--2Z3AC";
export var sliderBox = "OurClients-module--sliderBox--3WxoU";
export var title = "OurClients-module--title--1y9JB";
export var slider = "OurClients-module--slider--15yQp";
export var arrows = "OurClients-module--arrows--3XsLn";
export var disabled = "OurClients-module--disabled--3qZJm";
export var count = "OurClients-module--count--2492e";
export var slick = "OurClients-module--slick--29MDK";
export var itemBox = "OurClients-module--itemBox--1zd-y";
export var item = "OurClients-module--item--3nD_q";
export var photo = "OurClients-module--photo--1RQT4";
export var circle = "OurClients-module--circle--mguXc";
export var description = "OurClients-module--description--rwFQd";
export var quoteIcon = "OurClients-module--quoteIcon--a5Ysa";
export var sliderClientsBox = "OurClients-module--sliderClientsBox--tc-Zy";
export var listClients = "OurClients-module--listClients--3rMDP";
export var customDots = "OurClients-module--customDots--1iLlM";
export var client = "OurClients-module--client--1Ro2_";
export var box = "OurClients-module--box--3pPGs";