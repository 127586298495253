import unimed from '../images/clients/Unimed_Rio_Preto.jpg'
import cna from '../images/clients/cna.jpg'
import ctg from '../images/clients/CTG_Brasil.png'
import drager from '../images/clients/Drager.png'
import rodonaves from '../images/clients/Rodonaves.png'
import biosev from '../images/clients/biosev.jpg'
import seb from '../images/clients/seb.png'
import wtb from '../images/clients/wtb.png'

export const OurClientsList = [
  {
    title: 'Unimed Rio Preto',
    image: unimed,
  },
  {
    title: 'CNA',
    image: cna,
  },
  {
    title: 'CTG',
    image: ctg,
  },
  {
    title: 'Drager',
    image: drager,
  },
  {
    title: 'Rodonaves',
    image: rodonaves,
  },
  {
    title: 'BIOSEV',
    image: biosev,
  },
  {
    title: 'Grupo SEB',
    image: seb,
  },
  {
    title: 'WTB',
    image: wtb,
  },
]
