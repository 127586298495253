import React from 'react'
import { PageProps } from 'gatsby'

import { SEO } from '../components/SEO'
import { Solutions } from '../components/Solutions'
import { HeaderWrapper } from '../components/HeaderWrapper'
import { AboutUs } from '../components/AboutUs'
import { Statistics } from '../components/Statistics'
import { OurClients } from '../components/OurClients'
import { Testimonials } from '../components/Testimonials'
import { Layout } from '../components/layout'

const Home: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Consultoria em gestão de projeto - Valore Brasil" />
      <HeaderWrapper />
      <Solutions />
      <AboutUs />
      <Statistics largeMargin={true} />
      <Testimonials />
      <OurClients />
    </Layout>
  )
}

export default Home
