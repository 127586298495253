// extracted by mini-css-extract-plugin
export var softBlueGradient = "Testimonials-module--soft-blue-gradient--I99wC";
export var testimonials = "Testimonials-module--testimonials--1MweI";
export var fullContainer = "Testimonials-module--fullContainer--29vPD";
export var arrows = "Testimonials-module--arrows--xqDcf";
export var count = "Testimonials-module--count--1OhB1";
export var disabled = "Testimonials-module--disabled--bQNwZ";
export var title = "Testimonials-module--title--2m5Q8";
export var sliderClientsBox = "Testimonials-module--sliderClientsBox--gVmf1";
export var listClients = "Testimonials-module--listClients--2e28v";
export var client = "Testimonials-module--client--2gcr2";
export var box = "Testimonials-module--box--17Zbh";
export var quoteIcon = "Testimonials-module--quoteIcon--11DD2";