import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from '../styles/components/OurClients.module.scss'
import { OurClientsList } from '../utils/OurClients'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons'

import avatar1 from '../images/clients/teste3.png'
import avatar2 from '../images/clients/teste.jpg'
import avatar3 from '../images/clients/teste2.jpg'
import MoreInfoCard from './MoreInfoCard'

const clients = [
  {
    photo: avatar1,
    name: 'Teddy Test',
    role: 'Head de Operações - Empresa Teste',
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue neque iaculis porttitor ornare. Morbi sollicitudin auctor magna quis volutpat. Sed mi leo, efficitur ac ultricies in, faucibus non tellus. Nam ornare augue et tortor ornare sodales. Vivamus pulvinar, nibh in convallis dictum, tellus erat sollicitudin purus`,
  },
  {
    photo: avatar2,
    name: 'Teddy Test 2',
    role: 'CTO - Empresa Teste 2',
    text: 'Lorem ipsum dolor sit amet, consectetur auctor magna quis volutpat. Sed mi leo, efficitur ac ultricies in, faucibus non tellus. Nam ornare augue et tortor ornare sodales. Vivamus pulvinar, nibh in convallis dictum, tellus erat sollicitudin purus',
  },
  {
    photo: avatar3,
    name: 'Gina Test',
    role: 'Head de Operações - Empresa Teste 3',
    text: 'Lorem ipsum dolor sit amet, neque iaculis porttitor ornare. Morbi sollicitudin auctor magna quis volutpat. Sed mi leo, efficitur ac ultricies in, faucibus non tellus. Nam ornare augue et tortor ornare sodales. Vivamus pulvinar, nibh in convallis dictum, tellus erat sollicitudin purus',
  },
]

export const OurClients: React.FC = () => {
  return (
    <section className={clsx([styles.clients])}>
      <div className="container">
        {/* <div className={clsx([styles.sliderBox, 'row'])}>
          <Testemonials />
        </div> */}
        <div className={clsx([styles.sliderClientsBox, 'row'])}>
          <div className={clsx(['col-12'])}>
            <Slider
              className={styles.listClients}
              adaptiveHeight={false}
              infinite={true}
              autoplay={false}
              slidesToShow={5}
              slidesToScroll={1}
              dots={false}
              draggable={false}
              speed={300}
              arrows={true}
              nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
              prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
              dotsClass={clsx([`slick-dots`, styles.customDots])}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                  },
                },
              ]}
            >
              {OurClientsList.map((client, index) => (
                <div className={styles.client} key={index}>
                  <div className={styles.box}>
                    <img
                      src={client.image}
                      alt={client.title}
                      title={client.title}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* <MoreInfoCard
        title={'Quer saber mais sobre nossos cases de sucessos?'}
        description={
          'Pellentesque interdum finibus augue a semper. Praesent malesuada magna nec sapien ullamcorper auctor.'
        }
        link={'/#contato'}
        sameOrigin
        linkText={'Fale com um especialista'}
      /> */}
    </section>
  )
}

const Testemonials: React.FC = () => {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState({
    slideIndex: 1,
  })

  return (
    <>
      <div className={clsx([styles.title, 'col-12 col-md-4'])}>
        <h2>Nossos Clientes</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue
          neque iaculis porttitor ornare.
        </p>
      </div>
      <div className={clsx([styles.slider, 'col-12 col-md-8'])}>
        <div className={styles.arrows}>
          <span
            onClick={() => {
              sliderRef.current?.slickPrev()
            }}
            className={currentSlide.slideIndex === 1 ? styles.disabled : ''}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className={styles.count}>
            {currentSlide.slideIndex}/{clients.length}
          </div>
          <span
            onClick={() => {
              sliderRef.current?.slickNext()
            }}
            className={
              currentSlide.slideIndex === clients.length ? styles.disabled : ''
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>
        <Slider
          ref={sliderRef}
          className={styles.slick}
          adaptiveHeight={true}
          infinite={false}
          autoplay={false}
          slidesToShow={1}
          slidesToScroll={1}
          dots={false}
          draggable={false}
          speed={300}
          arrows={false}
          beforeChange={(current, next) =>
            setCurrentSlide((prevState) => ({
              ...prevState,
              slideIndex: next + 1,
            }))
          }
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
                arrows: false,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
              },
            },
          ]}
        >
          {clients.map((client, index) => (
            <div className={styles.itemBox} key={index}>
              <div className={styles.item}>
                <div className={styles.photo}>
                  <div className={styles.circle}>
                    <img src={client.photo} alt={client.name} />
                  </div>
                </div>
                <div className={styles.description}>
                  <p>
                    <i>" </i>
                    {client.text}
                    <i>"</i>
                  </p>
                  <strong>{client.name}</strong>
                  <small>{client.role}</small>
                  <FontAwesomeIcon
                    className={styles.quoteIcon}
                    icon={faQuoteRight}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}
