import React from 'react'
import * as styles from '../styles/components/Solutions.module.scss'

import { SectionTitle } from '../components/SectionTitle'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import iconBPM from '../images/project-management.svg'
import iconRPA from '../images/icon-robot.svg'
import iconBudget from '../images/icon-budget.svg'
import iconSGI from '../images/icon-quality-control.svg'
import iconOKR from '../images/icon-goal.svg'
import iconCompliance from '../images/icon-alert.svg'

const services = [
  {
    title: 'Melhorias de processos BPM',
    text: `Através do mapeamento dos processos iremos sugerir boas práticas de
    mercado para padronizar e melhorar os processos de acordo com o
    planejamento estratégico.`,
    icon: <img src={iconBPM} alt="BPM" title="Melhorias de processos BPM" />,
    link: '/solucoes/gestao-por-processos-de-negocio-bpm',
    sameOrigin: true,
  },
  {
    title: 'RPA',
    text: `Conheça nossa ferramenta para robotização de processos: Roberty
    Automation. Automatize tarefas operacionais, manuais e repetitivas.
    Invista o capital intelectual humano em tarefas de maior racicínio e
    criatividade.`,
    icon: <img src={iconRPA} alt="RPA" title="RPA" />,
    link: 'https://roberty.app',
    sameOrigin: false,
  },
  {
    title: 'Controladoria',
    text: `Alavanque seus resultados. Atuamos na Formação de Preço de Venda,
    Apuração de Custos, Implantação de Controladoria, Planejamento e
    Controle Orçamentário, Consultoria Financeira, Análise de Viabilidade,
    dentre outros
    `,
    icon: <img src={iconBudget} alt="Controladoria" title="Controladoria" />,
    link: '/solucoes/controladoria',
    sameOrigin: true,
  },
  {
    title: 'Sistema de Gestão Integrado - SGI',
    text: ` Atuamos desde a implantação do Sistema de Gestão até a preparação
    para as certificações reconhecidas nacional e internacionalmente:
    ISO 9001, ISO 19600, ISO 31000, ISO 14001, ISO 45001 e ISO 27001.`,
    icon: <img src={iconSGI} alt="SGI" title="SGI" />,
    link: '/solucoes/sistema-de-gestao-integrada',
    sameOrigin: true,
  },
  {
    title: 'OKR',
    text: `Conheça nossa metodologia para definição de objetivos estratégicos
    ligados aos resultados-chave. Implantamos a gestão à vista para que
    todos saibam onde têm que chegar.`,
    icon: <img src={iconOKR} alt="OKR" title="OKR" />,
    link: '/solucoes/okr-objetivos-e-resultados-chaves',
    sameOrigin: true,
  },
  {
    title: 'Compliance',
    text: `Fazemos um diagnóstico para entender como está a integridade e a
    gestão de riscos da organização e de acordo com a prioridade atacamos
    as falhas através de políticas e controles internos.`,
    icon: <img src={iconCompliance} alt="Compliance" title="Compliance" />,
    link: '/solucoes/compliance',
    sameOrigin: true,
  },
]

export const Solutions: React.FC = () => {
  return (
    <section id="solucoes" className={styles.solutions}>
      <div className="pt-3 pb-5">
        <div className="container containerLarge">
          <SectionTitle
            title="Principais Soluções"
            className="isWhite"
            whiteFavicon
          />
          <div className={styles.solutionsBox}>
            {services.map((service, key) =>
              service.sameOrigin ? (
                <>
                  <AnchorLink
                    to={service.link}
                    className={styles.item}
                    key={key}
                  >
                    <div className={styles.image}>{service.icon}</div>
                    <div className={styles.info}>
                      <h2>{service.title}</h2>
                      <p>{service.text}</p>
                    </div>
                  </AnchorLink>
                </>
              ) : (
                <>
                  <a
                    href={service.link}
                    target="_blank"
                    className={styles.item}
                    key={key}
                  >
                    <div className={styles.image}>{service.icon}</div>
                    <div className={styles.info}>
                      <h2>{service.title}</h2>
                      <p>{service.text}</p>
                    </div>
                  </a>
                </>
              )
            )}
            <div className={styles.contactBox}>
              <div className={styles.text}>
                <p>
                  Propomos soluções que geram valor com garantia de satisfação e
                  compromisso com as melhores práticas de mercado. Prova disso
                  são os nossos inúmeros cases de sucesso. Junte-se a nós,
                  comece agora mesmo o processo de transformação.
                </p>
              </div>
              <div className={styles.cta}>
                <AnchorLink to={`/#contato`}>
                  <button>Fale com um especialista</button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
