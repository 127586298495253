import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from '../styles/components/Testimonials.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons'

import avatar1 from '../images/clients/teste3.png'
import avatar2 from '../images/clients/teste.jpg'
import avatar3 from '../images/clients/teste2.jpg'

const clients = [
  {
    photo: avatar1,
    name: 'Grupo EcoRodovias',
    role: 'São Paulo - SP',
    text: `Após concluído o projeto, foram mapeados e atualizados mais de 470 documentos, entre processos e subprocessos de negócio da EcoRodovias. Com este trabalho, a empresa cliente passou a ter maior visibilidade dos processos em seu estado atual, permitindo tomar decisões mais assertivas, padronizar tarefas e propor melhorias.`,
  },
  {
    photo: avatar1,
    name: 'MMarra Distribuidora Automotiva',
    role: 'Ribeirão Preto - SP',
    text: `Após concluído o projeto junto à MMarra Distribuidora, foram mapeados os processos de logística e implementados seus procedimentos operacionais, desde o recebimento ao despacho de materiais. Foram levantadas 40 oportunidades de melhoria, trabalhando-se a cultura organizacional para aplicação de boas práticas de Lean, Otimização de Layout e Metodologia 5S.`,
  },
  {
    photo: avatar1,
    name: 'CTG Brasil',
    role: 'São Paulo - SP',
    text: `A CTG Brasil, passou a ter 100% das operações certificadas nas normas ISO! São 14 usinas hidrelétricas em conformidade com a ISO 55001, ISO 45001, ISO 14001 e ISO 9001. Para isso, foram implementados mais de 800 planos de ação, aplicadas 1.300 horas de treinamento e controlados mais de 22.000 requisitos legais, o que levou cerca de 2 anos e colocou a companhia entre as protagonistas do setor.`,
  },
  {
    photo: avatar1,
    name: 'Parque Tecnológico Itaipu',
    role: 'Foz do Iguaçu - PR',
    text: `O treinamento realizado junto ao Parque Tecnológico Itaipu possibilitou o nivelamento de importantes conceitos da Gestão por Processos de Negócio (BPM) e desenvolvimento de técnicas de melhorias de processo. Mais do que mera documentação, atualmente a equipe do escritório é capaz de conectar os objetivos estratégicos às metas, que conduzirão a melhorias relevantes para a organização.`,
  },
  {
    photo: avatar1,
    name: 'Grupo Vitti',
    role: 'Goiânia - GO',
    text: `O Projeto realizado com a empresa Goiasfiller Mineração, pertencente ao Grupo Vitti
     – atuante no mercado há quase 50 anos - teve como objetivo elevar a gestão de resultados da empresa,
     identificando a rentabilidade de seus produtos. Após o projeto, com os indicadores de custo
     por produto, margem bruta por produto, margem líquida por produto, markup e preço mínimo, 
     foi possível o cliente tomar decisões mais assertivas de quais 
     produtos manter em seu portfólio e melhorar sua precificação para 
     ganhar competitividade frente ao mercado.`,
  },
  {
    photo: avatar1,
    name: 'OKUBO',
    role: 'Ribeirão Preto - SP',
    text: `Após o projeto foram quase 40 melhorias desenvolvidas, ao longo de
    8 macroprocessos, onde 30% delas afetaram diretamente a estratégia
    da empresa, tais como a integração através de S&OP, Estruturação
    Comercial em Células, Unificação do Supply Chain, Inteligência de
    Mercado e Segurança de Dados. Outra grande entrega foi a aderência
    do sistema ERP atual, possibilitando avaliar melhor a realidade existente
    e suportando a decisão de troca para um novo ERP de mercado. Também
    passou a existir o Escritório de Processos dentro da empresa, que hoje
    funciona sem a dependência da consultoria.`,
  },
  {
    photo: avatar2,
    name: 'BRASIF',
    role: 'Jundiaí - SP',
    text: `O time de processos da Brasif foi capacitado através do Treinamento para
    Formação de Analistas de Processos BPM e com a mentoria para criação
    do escritório de processos. Com o progresso adquirido, foi capaz de
    executar a metodologia de forma completa no desenvolvimento de melhorias
    de processos, desde o mapeamento AS IS, passando pela construção das
    melhorias através de técnicas como o "brownpaper".`,
  },
  {
    photo: avatar3,
    name: 'TREVISAN, PEREIRA & CARMONA SOCIEDADE DE ADVOGADOS',
    role: 'Ribeirão Preto - SP',
    text: `Após o projeto a TPC foi capaz de avaliar melhor o resultado em
    comparação com as metas estabelecidas durante o projeto e
    consequentemente buscar melhores resultados. Uma rotina de gestão
    também foi estabelecida para o gerenciamento dos controles operacionais
    e para que as principais descisões fossem pautadas nos indicadores
    estratégicos (KPIs), possibilitando assim, crescimento seguro e
    sustentável.`,
  },
  {
    photo: avatar3,
    name: 'TECCA ENGENHARIA DE SISTEMAS',
    role: 'São Paulo - SP',
    text: `Após o projeto a empresa obteve maior assertividade na formação do preço
    de venda. O trabalho foi feito com foco nos custos diretos e nos
    créditos e débitos dos impostos, possibilitando uma melhor percepção
    da concorrência e aumentando a competitividade da empresa e como
    consequência foram fechados dois grandes negócios no curto prazo.`,
  },
  {
    photo: avatar3,
    name: 'DIMAN SERVIÇOS MECÂNICOS',
    role: 'Ribeirão Preto - SP',
    text: `Após 4 meses de projeto houveram mudanças dentro da gestão da Diman,
    tais como classificação adequada de gastos, extração de relatórios
    de resultados diretamente do sistema, com resultado realizado do ano e
    um orçamento previsto para o ano seguinte com as principais metas. Houve
    ainda a implantação da rotina de gestão através de uma reunião de
    análise de resultados a partir dos desvios do previsto x realizado e
    o desenvolvimento de planos de ação para os envolvidos da empresa
    alcançarem os resultados previstos.`,
  },
]

export const Testimonials: React.FC = () => {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState({
    slideIndex: 1,
  })

  return (
    <section id="cases-sucesso" className={clsx([styles.testimonials])}>
      <div className={clsx(styles.fullContainer, 'containerLarge')}>
        <div className={styles.arrows}>
          <span
            onClick={() => {
              sliderRef.current?.slickPrev()
            }}
            className={currentSlide.slideIndex === 1 ? styles.disabled : ''}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <div className={styles.count}>
            {currentSlide.slideIndex}/{clients.length}
          </div>
          <span
            onClick={() => {
              sliderRef.current?.slickNext()
            }}
            className={
              currentSlide.slideIndex === clients.length ? styles.disabled : ''
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>
        <div className={clsx([styles.title, 'col-12 col-md-4'])}>
          <h2>Nossos clientes</h2>
          <p>Saiba mais sobre nossos cases de sucesso.</p>
        </div>
        <div className={clsx([styles.sliderClientsBox])}>
          <div className={clsx(['col-12'])}>
            <Slider
              ref={sliderRef}
              className={styles.listClients}
              adaptiveHeight={false}
              infinite={false}
              autoplay={false}
              slidesToScroll={1}
              dots={false}
              draggable={true}
              speed={300}
              arrows={false}
              variableWidth={true}
              beforeChange={(current, next) =>
                setCurrentSlide((prevState) => ({
                  ...prevState,
                  slideIndex: next + 1,
                }))
              }
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    adaptiveHeight: true,
                    variableWidth: false,
                  },
                },
              ]}
            >
              {clients.map((client, index) => (
                <div className={styles.client} key={index}>
                  <div className={styles.box}>
                    <p>
                      <i>" </i>
                      {client.text}
                      <i>"</i>
                    </p>
                    <strong>{client.name}</strong>
                    <small>{client.role}</small>
                    <FontAwesomeIcon
                      className={styles.quoteIcon}
                      icon={faQuoteRight}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
